<template>
  <div>
    <div class="bread">
      <div>
        首页 > 会议 > 会议详情
      </div>
    </div>
    <div class="play">
      <div class="content">
        <div class="left">
          <div class="leftUpper">
            <div class="leftUpperImg">
              <img :src="$global.regexImg(infoObj.info.logo_web)"
                   alt="">
            </div>
            <div class="leftUpperWord">
              <div class="leftUpperWordTit">
                {{infoObj.info.meet_name}}
              </div>
              <div class="leftUpperWordLabel">
                <div class="leftUpperWordLabelNum">
                  <div class="leftUpperWordLabelNumOne">
                    <div style="margin-right: 29px">{{infoObj.info.start_date}} - {{infoObj.info.end_date}}</div>
                  </div>
                  <div class="leftUpperWordLabelNumOne">
                    <div style="margin-right: 29px">{{infoObj.info.address}}</div>
                  </div>
                </div>
                <div class="leftUpperWordLabelClick"
                     v-if='infoObj.info.is_end == 2'>
                  <div class="leftUpperWordLabelClickBut">
                    <div class="leftUpperWordLabelClickButOne"
                         v-if='infoObj.is_apply == 2'
                         @click="onSignUp()">
                      一键报名
                    </div>
                    <div class="leftUpperWordLabelClickButOne"
                         v-if='infoObj.is_apply == 1'
                         @click="onOrderDetail(infoObj.order_id)">
                      订单详情
                    </div>
                    <div class="
                         leftUpperWordLabelClickButTwo"
                         v-if='infoObj.info.have_hotel == 1'
                         @click="onHodel()">
                      预定酒店
                    </div>
                  </div>
                  <div class="leftUpperWordLabelClickOperation">
                    <div class="leftUpperWordLabelClickOperationImg"
                         @click="shareClick">
                      <img src="@/assets/img/play/183.png"
                           alt="" />
                      <span>分享</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- tab 切换 -->
          <div class="leftLower">
            <div style="">
              <div class="leftLowerTab">
                <div class="tabStyle"
                     :class="{tabStyleBorder:tabIndex==ie.id}"
                     v-for="(ie,ia) in tabContent"
                     :key="ia"
                     @click="tabClick(ie)">
                  <div>{{ie.name}}</div>
                </div>
              </div>
              <!-- 会议简介 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==1">
                <curriculum :wordHtml="infoObj.info.intro"></curriculum>
              </div>
              <!-- 嘉宾介绍 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==2">
                <div class="lecturer_box"
                     v-if='infoObj.lecturer && infoObj.lecturer.length > 0'>
                  <div v-for='(ci,i) in infoObj.lecturer'
                       @click="goother({path:'/teacherdetail', query:{id:ci.id,activeTab:1}})"
                       class="row"
                       :key='i'>
                    <img :src="$global.regexImg(ci.portrait)"
                         alt="">
                    <div class="text1">{{ci.real_name}}</div>
                    <div class="text2">{{ci.duties}}</div>
                    <div class="text3">{{ci.explain}}</div>
                  </div>
                </div>
                <tempData v-else />
                <!-- <teacher-list :theacherList="im"></teacher-list> -->
              </div>
              <!-- 活动日程 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==3">
                <itinerary :lecturer='infoObj.lecturer'
                           v-if='infoObj.date && infoObj.date.length > 0'
                           :time='infoObj.date'
                           :id='meetingId'></itinerary>
              </div>
              <!-- 展商名录 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==4">
                <exh-list :list='infoObj.exhibitor'
                          v-if='infoObj.exhibitor && infoObj.exhibitor.length > 0'></exh-list>
                <tempData v-else />
              </div>
              <!-- 展会指南 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==5">
                <curriculum :wordHtml="infoObj.info.meet_guide"></curriculum>
              </div>
              <!-- 参会咨询 -->
              <div class="htmlWordStyle"
                   v-if="tabIndex==6">
                <curriculum :wordHtml="infoObj.info.meet_consult"></curriculum>
              </div>
            </div>
          </div>
        </div>
      </div>
      <textarea style="opacity:0;width:1px;height:1px"
                id="input"
                key="textarea">复制</textarea>
    </div>
  </div>
</template>

<script>
import { get_meeting_Detail } from '@/utils/Api/meeting'
import exhList from '@/components/exhList'
import tempData from '@/components/tempData'
import itinerary from '@/components/itinerary'
import curriculum from '@/components/curriculum'
export default {
  components: {
    exhList, itinerary, curriculum, tempData
  },
  data () {
    return {
      meetingId: null,
      tabIndex: 3,//tab切换下标
      infoObj: { info: {}, lecturer: [], date: [], exhibitor: [] },
      tabContent: [
        {
          id: 1,
          name: '会议简介',
          page: 1
        },
      ],//tab切换展示

      share: false,//分享
      poster: null,//海报接口返回内容
      qrCode: null,//二维码
      la: null,
    }
  },
  created () {
    this.meetingId = this.$route.query.meetingId
    this.details()
  },
  methods: {
    onOrderDetail (id) {
      this.$router.push({ path: 'personal/meetingOrderDetail', query: { orderId: id } })
    },
    // 课程详情接口
    async details () {
      this.infoObj = await get_meeting_Detail({ meet_id: this.meetingId })
      if (this.infoObj.lecturer.length) this.tabContent.push({
        id: 2,
        name: '嘉宾介绍',
        page: 1
      })
      if (this.infoObj.date.length) this.tabContent.push({
        id: 3,
        name: '活动日程',
        page: 1
      })
      if (this.infoObj.exhibitor.length) {
        this.tabContent.push({
          id: 4,
          name: '展商名录',
          page: 1
        })
      }
      if (this.infoObj.info.meet_guide) this.tabContent.push({
        id: 5,
        name: '展会指南',
        page: 1
      })
      if (this.infoObj.info.meet_consult) this.tabContent.push({
        id: 6,
        name: '参会咨询',
        page: 1
      })
    },
    onSignUp () {
      if (!this.$store.getters.token) {
        this.$confirm('请先登录账号,', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ path: '/login', query: { redirect: encodeURI(this.$router.app._route.fullPath) } })
        }).catch(() => {
        });
      } else {
        this.$router.push({ path: '/registrationFilling', query: { meetingId: this.infoObj.info.id, is_others: this.infoObj.info.others } })
      }

    },
    onHodel () {
      if (!this.$store.getters.token) {
        this.$confirm('请先登录账号,', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ path: '/login', query: { redirect: encodeURI(this.$router.app._route.fullPath) } })
        }).catch(() => {
        });
      } else {
        this.goother({ path: '/hotelReservation', query: { meetingId: this.meetingId } })
      }
    },
    // tab点击事件
    tabClick (e) {
      console.log('点击', e)
      this.tabIndex = e.id
    },
    // 分享按钮
    shareClick () {
      if (!this.$store.getters.token) {
        this.$message.error(`请先登录账号`);
        return;
      }
      var input = document.getElementById('input')
      // var url = window.location.href
      var url = `http://h5test.test02.qcw100.com:8000/#/meetingDetail?meetingId=${this.meetingId}`
      input.value = url
      input.select()
      document.execCommand('copy')
      this.$message.success('当前链接已复制，快去分享吧')

    },
  },
};
</script>

<style scoped >
@import url('./meetingDetail.css');
.lecturer_box {
  width: 1400px;
  background-color: #fafafa;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.lecturer_box .row {
  width: 228px;
  height: 311px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 15px 15px 0;
  display: flex;
  flex-direction: column;
  align-self: center;
}
.lecturer_box img {
  margin: 0 auto;
  margin-top: 10px;
  width: 127px;
  height: 127px;
  border-radius: 50%;
}
.lecturer_box .row .text1 {
  margin-top: 14px;
  font-size: 20px;
  line-height: 36px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
}
.lecturer_box .row .text2 {
  color: #898989;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lecturer_box .row .text3 {
  color: #898989;
  font-size: 14px;
  line-height: 22px;
  display: block;
  overflow: hidden;
  text-overflow: ellispsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>