<template>
    <div class="tempData flex alignCenter spacearound">
        <div style="text-align:center;" class="flex flexColumn alignCenter">
            <img src="@/assets/img/common/tempData.png" style="width:300px;height:300px" alt="">
            {{shouwtext}}
        </div>
    </div>
</template>
<script>
export default {
    name:'tempData',
    props:{
        shouwtext:{
            type: String,
            default: '暂无数据'
        }
    }
}
</script>
<style scoped>
.tempData{
    width: 100%;
    height: 100%;
}
</style>