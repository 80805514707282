import * as api from './http'

/* 会议相关  2期wwl*/
/* export function order_detail(params){
    return api.postApi('goods/order_detail', params)
} */

/* 会议列表 */
export function get_meeting_list (params) {
  return api.getApi('Home/meetList', params)
}

export function get_meeting_Detail (params) {
  return api.getApi('Home/meetInfo', params)
}

export function get_meeting_altar (params) {
  return api.getApi('Home/forum', params)
}

export function get_schedule_list (params) {
  return api.getApi('Meet/myJoin', params)
}

export function get_schedule_list_pc (params) {
  return api.getApi('Meet/myJoinPc', params)
}

// 企业详情
export function get_exhibitor (params) {
  return api.getApi('Home/exhibitor', params)
}
// 企业沟通
export function set_reserve (params) {
  return api.getApi('Meet/reserve', params)
}
// 导师出席
export function get_tutor_attend (params) {
  return api.getApi('Meet/attend', params)
}
// 获取酒店列表
export function get_hotel (params) {
  return api.getApi('Meet/hotel', params)
}
// 酒店详情
export function get_hotel_detail (params) {
  return api.getApi('Meet/room', params)
}

export function get_ticket_select (params) {
  return api.getApi('Meet/ticketSelect', params)
}

export function get_demand_info (params) {
  return api.getApi('Meet/ticketInfo', params)
}

export function get_invoice_setting (params) {
  return api.getApi('Meet/invoice', params)
}

export function get_discount (params) {
  return api.getApi('Meet/selectCompany', params)
}

export function generate_order (params) {
  return api.postApi('Meet/addApply', params)

}

export function set_ticketPay (params) {
  return api.postApi('Meet/ticketPay', params)

}

export function get_payChannel (params) {
  return api.getApi('Meet/payChannel', params)
}

export function get_hotelOrder (params) {
  return api.getApi('Meet/hotelOrder', params)
}

export function set_cancelJoin (params) {
  return api.postApi('Meet/cancelJoin', params)
}

export function set_cancelRoom (params) {
  return api.postApi('Meet/cancelRoom', params)
}

export function get_mealTicket (params) {
  return api.postApi('Meet/mealTicket', params)
}

// 酒店订单
export function set_roomOrder (params) {
  return api.postApi('Meet/roomOrder', params)
}
// 酒店支付
export function set_payRoom (params) {
  return api.postApi('Meet/payRoom', params)
}

// 退款
export function set_refund (params) {
  return api.postApi('Meet/refund', params)
}





