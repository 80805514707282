<template>
  <!-- 课程详情 课程介绍 -->
  <div class="curriculumHtml">
    <div v-html="wordHtml"></div>
  </div>
</template>

<script>
export default {
  name: "curriculumHtml",
  props: {
    wordHtml: {
      type: String,
      default: "",
    },
  },
  data () {
    return {};
  },
  methods: {},
};
</script>

<style>
.curriculumHtml {
  widows: 93%;
  /* height: 1381px; */
  box-sizing: border-box;
  padding: 0 30px;
  overflow-y: auto;
  text-align: initial;
}
/* .curriculumHtml::-webkit-scrollbar{
    display: none;
} */
</style>