<template>
  <div class="exhList flex flexWrap ">
    <!-- flex flexColumn alignCenter spacebetween -->
    <div class="exhListInfo "
         @click="get_info(im.id)"
         v-for="(im,ix) in list"
         :key="ix">
      <img :src="$global.regexImg(im.avatar)"
           alt="">
      <div class="text"> {{im.name}} </div>
    </div>
    <!-- 企业详情 -->
    <el-dialog :visible.sync="detailObj.visible"
               width="850px">
      <div class="detail_box">
        <div class="top_box">
          <img :src="$global.regexImg(detailObj.data.avatar)"
               alt="">
          <div class="right">
            <div>{{detailObj.data.name}}</div>
            <el-button :loading='detailObj.loading'
                       @click="onCommunicate()"
                       class="button">预约沟通</el-button>
          </div>
        </div>
        <div class="bottom_box">
          <div class="title">
            企业简介
          </div>
          <curriculum class="content"
                      :wordHtml="detailObj.data.intro"></curriculum>
        </div>
      </div>
    </el-dialog>
    <!-- 沟通 -->
    <el-dialog :visible.sync="communicate.visible"
               center
               width="485px">
      <div class="communicate_box">
        <div>预约企业</div>
        <div>请留下您的联系方式，我们将安排专人与您进行联系</div>
        <el-form :model="communicate"
                 :rules='loginRules'
                 label-position='left'
                 ref="communicate">
          <div>
            <el-form-item prop="name"
                          label="姓名：">
              <el-input v-model="communicate.name"
                        style="width: 320px;height:46px"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="phone"
                          label="电话：">
              <el-input v-model="communicate.phone"
                        style="width: 320px"
                        placeholder="请输入电话号码"></el-input>
            </el-form-item>
          </div>
          <el-row class="button_box">
            <el-button class="button button1"
                       @click="communicate.visible = false">取消</el-button>
            <el-button class="button button2"
                       :loading='communicate.loading'
                       @click="submitForm('communicate')">确定</el-button>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import curriculum from '@/components/curriculum'
import { get_exhibitor, set_reserve } from '@/utils/Api/meeting'
import { setTimeout } from 'timers';
export default {
  components: { curriculum },
  name: 'exhList',
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      loginRules: {
        name: [
          { required: true, message: '请输入姓名' },
          { pattern: /^([\u4E00-\u9FA5A-Za-z\d]{2,15})$/, message: '姓名由2-15位数字、字母和汉字组成' }
        ],
        phone: [
          { required: true, message: '请您输入电话号码' },
          { pattern: /^1[3-9]\d{9}$/, message: '请填写正确的电话号码' }
        ]
      },
      detailObj: {
        visible: false,
        data: {},
        id: null,
        loading: false
      },
      communicate: {
        visible: false,
        phone: null,
        name: null,
        loading: null,
      },
    }
  },
  methods: {
    onCommunicate () {
      if (!this.$store.getters.token) {
        this.$confirm('请先登录账号,', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ path: '/login', query: { redirect: encodeURI(this.$router.app._route.fullPath) } })
        }).catch(() => {
        });
      } else {
        this.communicate.visible = true
        if (this.$refs.communicate) this.$refs.communicate.resetFields()
      }

    },
    async get_info (id) {
      let data = await get_exhibitor({ id })
      if (data && Object.keys(data).length > 0) {
        this.detailObj.data = data
        this.detailObj.id = id
        this.detailObj.visible = true
      } else {
        this.$message.error(`获取企业失败`);
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.communicate.loading = true
          try {
            var data = await set_reserve({
              name: this.communicate.name,
              phone: this.communicate.phone,
              exhibitor_id: this.detailObj.id
            })
            this.communicate.loading = false
            this.communicate.visible = false
            this.$message.success(`您已提交沟通信息，我们工作人员会及时与您联系！`);
          } catch{
            this.communicate.loading = false
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.exhList {
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  justify-content: flex-start;
  margin-left: 50px;
  .exhListInfo {
    margin-right: 28px;
    cursor: pointer;
    width: 246px;
    // height: 190px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    img {
      width: 246px;
      height: 246px;
      border-radius: 4px;
      margin-bottom: 10px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }
    font-size: 15px;
    color: #333333;
    margin-top: 28px;
  }
  .text {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/deep/ .el-dialog {
  border-radius: 40px;
}
/deep/ .el-dialog__headerbtn {
  font-size: 32px;
  .el-dialog__close {
    color: #333;
  }
}
.detail_box {
  width: 100%;
  // height: 60vh;
  padding: 20px;
  box-sizing: border-box;
  .bottom_box {
    max-height: calc(60vh - 150px);
    overflow-y: auto;
    margin-top: 32px;
    border-top: 1px solid #eaeaea;
    width: 100%;
    box-sizing: border-box;
    padding-top: 32px;
    .title {
      text-align: left;
      font-size: 18px;
      color: #333;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .content {
      width: 100%;
      font-size: 14px;
      color: #333;
      /deep/ img {
        max-width: 70% !important;
        box-sizing: border-box;
      }
    }
  }
  .top_box {
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    height: 202px;
    display: flex;
    justify-content: space-between;
    img {
      width: 202px;
      height: 202px;
      border-radius: 4px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    }
    .right {
      width: calc(100% - 240px);
      height: 202px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      div {
        font-size: 18px;
        font-weight: 700;
      }
      .button {
        border: 0;
        width: 108px;
        height: 40px;
        opacity: 1;
        background: #47d7e3;
        border-radius: 4px;
        font-size: 16px;
        color: #fff;
      }
    }
  }
}
.communicate_box {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  > div:nth-child(1) {
    font-size: 20px;
    line-height: 32px;
    color: #333;
    margin-bottom: 16px;
    font-weight: 700;
  }
  > div:nth-child(2) {
    font-size: 15px;
    line-height: 22px;
    color: #999999;
    margin-bottom: 30px;
  }
  .el-input__inner {
    height: 46px;
  }
}
.button_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
  .button {
    width: 168px;
    height: 46px;
    background: #ffffff;
    border: 1px solid #c6c6c6;
    border-radius: 2px;
    color: #979797;
    font-size: 16px;
  }
  .button2 {
    border: 1px solid #47d7e3;
    background-color: #47d7e3;
    color: #fff;
  }
}
</style>
