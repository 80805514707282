<template>
  <!-- 活动日程 -->
  <div class="itinerary">
    <div class="itineraryDataList">
      <div @click="change(row)"
           class='dataList'
           :class="row == active  ? 'isCheck' :''"
           v-for="(row,i) in time"
           :key='i'><span>{{row}}</span>
      </div>
      <!-- </div> -->
    </div>
    <template v-if='list && list.length > 0'>
      <div class="infoList"
           v-for="(im, ix) in list"
           :key="ix">
        <div class="infoListTitle c33 fontW">{{im.name}}</div>

        <div class="infoDetail">
          <div class="infoDetailTitle flex alignCenter">
            <div class="infoDetailTime flex alignCenter spacearound">会议时间</div>
            <div class="infoDetailContent">
              环节/内容
            </div>
          </div>
          <div class="infoDetailList flex alignCenter"
               v-for="(km, kx) in im.lecturer"
               :key="kx">
            <div class="infoDetailTime">
              {{km.start_time}} - {{km.end_time}}
            </div>
            <div class="infoDetailContent">
              <div class="infoDetailContentTop c33">
                {{km.title}}
              </div>
              <div class="infoDetailContentpersonal c33 flex alignCenter"
                   v-if='lecturer.find(c=>c.id == km.lecturer_id ) != undefined'
                   @click="goother({path:'/teacherdetail', query:{id:km.lecturer_id,activeTab:1}})">
                <img :src="$global.regexImg(lecturer.find(c=>c.id == km.lecturer_id ).portrait)"
                     style="margin-right: 11px;background:#47d7e3;"
                     alt="">
                {{lecturer.find(c=>c.id == km.lecturer_id ).real_name}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
    <temp-data v-else></temp-data>

  </div>
</template>

<script>
import tempData from '@/components/tempData'
import { get_meeting_altar } from '@/utils/Api/meeting'
export default {
  components: { tempData },
  name: 'itinerary',
  props: {
    lecturer: {
      type: Array,
      default: []
    },
    id: {
      type: [Array, String],
      default: null
    },
    time: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      active: null,
      list: [],
    }
  },
  methods: {
    change (row) {
      if (row == this.active) return
      this.active = row
      this.getList()
    },
    async getList () {
      this.list = await get_meeting_altar({ meet_id: this.id, date: this.active })
    }
  },
  created () {
    this.active = this.time[0]
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.itinerary {
  padding-bottom: 50px;
  .infoDetailList {
    width: 100%;
    height: 108px;
    background: #fafafa;
    &:nth-child(odd) {
      background: #ebebeb;
    }
    .infoDetailTime {
      width: 219px;
      font-size: 16px;
      padding-top: 20px;
      text-align: center;
      color: #333333;
      cursor: default;
    }
    .infoDetailContent {
      font-size: 16px;
      color: #333333;
      cursor: default;
      .infoDetailContentTop {
        font-size: 16px;
        margin-top: 20px;
      }
      .infoDetailContentpersonal {
        margin-top: 16px;
        font-size: 14px;
        color: #333333;
        img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
      }
    }
  }
  .infoList {
    margin-top: 20px;
    margin-left: 30px;
    text-align: left;
    .infoListTitle {
      font-size: 15px;
    }
    .infoDetail {
      margin-top: 16px;
      width: 780px;
      .infoDetailTitle {
        width: 100%;
        height: 40px;
        background: #47d7e3;
        .infoDetailTime {
          width: 219px;
          height: 100%;
          font-size: 14px;
          color: #ffffff;
          cursor: default;
        }
        .infoDetailContent {
          font-size: 14px;
          color: #ffffff;
          cursor: default;
        }
      }
    }
  }
  .itineraryDataList {
    width: 780px;
    height: 40px;
    background: #f5f8fc;
    margin-bottom: 10px;
    margin-left: 30px;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
    .dataList {
      float: left;
      height: 40px;
      line-height: 40px;
      // padding: 10px 50px;
      font-size: 14px;
      color: #333333;
      cursor: pointer;
      span {
        border-right: 1px solid #47d7e3;
        padding-left: 50px;
        padding-right: 50px;
      }
      &:last-child {
        span {
          border: none;
        }
      }
    }
  }
  .isCheck {
    background: #47d7e3;
    color: #ffffff;
  }
}
</style>
